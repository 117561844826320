// Alert above Aside component
.alert.top, .alert.top-right {
  z-index: 1051 !important;
}

// Sidebar heading color
.aside .aside-dialog .aside-header {
    background-color: @brand-primary !important;
}

// Darker color for input in editor
.editor-input > .form-control {
    color: @gray-darker;
}

// Clasic tabset - more contrast
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: @gray-darker;
    background-color: #fff;
}

// Custom navtabs for fragment components (eg. tabset of multiple tabs)
.nav-tabs-custom {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: @box-boxshadow;
  border-radius: @box-border-radius;
  > div > .nav-tabs {
    margin: 0;
    border-bottom-color: #f4f4f4;
    .border-top-radius(@box-border-radius);
    > li {
      border-top: 3px solid transparent;
      margin-bottom: -2px;
      > a {
        color: #444;
        .border-radius(0);
        &.text-muted {
          color: #999;
        }
        &,
        &:hover {
          background: transparent;
          margin: 0;
        }
        &:hover {
          color: #999;
        }
      }
      &:not(.active) {
        > a:hover,
        > a:focus,
        > a:active {
          border-color: transparent;
        }
      }
      margin-right: 5px;
    }

    > li.active {
      border-top-color: @light-blue;
      & > a,
      &:hover > a {
        background-color: #fff;
        color: #444;
      }
      > a {
        border-top-color: transparent;
        border-left-color: #f4f4f4;
        border-right-color: #f4f4f4;
      }

    }

    > li:first-of-type {
      margin-left: 0;
      &.active {
        > a {
          border-left-color: transparent;
        }
      }
    }

    //Pulled to the right
    &.pull-right {
      float: none !important;
      > li {
        float: right;
      }
      > li:first-of-type {
        margin-right: 0;
        > a {
          border-left-width: 1px;
        }
        &.active {
          > a {
            border-left-color: #f4f4f4;
            border-right-color: transparent;
          }
        }
      }
    }

    > li.header {
      line-height: 35px;
      padding: 0 10px;
      font-size: 20px;
      color: #444;
      > .fa,
      > .glyphicon,
      > .ion {
        margin-right: 5px;
      }
    }
  }

  > .tab-content {
    background: #fff;
    padding: 10px;
    .border-bottom-radius(@box-border-radius);
  }

  .dropdown.open > a {
    &:active,
    &:focus {
      background: transparent;
      color: #999;
    }
  }
  // Tab color variations
  &.tab-primary {
    > .nav-tabs {
      > li.active {
        border-top-color: @light-blue;
      }
    }
  }
  &.tab-info {
    > .nav-tabs {
      > li.active {
        border-top-color: @aqua;
      }
    }
  }
  &.tab-danger {
    > .nav-tabs {
      > li.active {
        border-top-color: @red;
      }
    }
  }
  &.tab-warning {
    > .nav-tabs {
      > li.active {
        border-top-color: @yellow;
      }
    }
  }
  &.tab-success {
    > .nav-tabs {
      > li.active {
        border-top-color: @green;
      }
    }
  }
  &.tab-default {
    > .nav-tabs {
      > li.active {
        border-top-color: @gray;
      }
    }
  }
}
