@import "bootstrap.less";
@import "AdminLTE.less";
@import "skin.less";
@import "components.less";

@light-blue: #00A388; // 79BD8F 00A388
@brand-primary: #00A388;

.content {
  padding-left: 0;
  padding-right: 0;
}

.form-control {
  color: @gray-darker;
}
